import * as React from 'react';

// MUI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

// Third Party
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from '@apollo/client';


// Internal
import Logo from '../../../assets/logo-primary.png';
import en from '../../../data/en.json';
import {UPSERT_USER} from '../../../data/graphql/mutations';

const rightLink = {
  fontSize: 16,
  color: 'primary.main',
  ml: 3,
};

function AppAppBar() {
  const [upsertUser, { error: UpsertUserError, data: UpsertUserData }] 
    = useMutation(UPSERT_USER);

  const { loginWithRedirect, user } = useAuth0();
  const { logout } = useAuth0();
  const { isAuthenticated, isLoading } = useAuth0();

  const [snackbarMessage, setSnackbarMessage] = React.useState<null|string>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      upsertUser({ variables: { 
        email: user?.email, 
        auth0_id: user?.sub, 
        role: "member", 
        name: user?.name,
        picture_url: user?.picture
       } 
      })
    }
  }, [isAuthenticated, user, upsertUser])  

  React.useEffect(() => {
    if (UpsertUserData !== undefined) {
      setSnackbarMessage(en.common.loginSuccess);
    }
    if (UpsertUserError !== undefined) {
      setSnackbarMessage(en.common.loginFail);
    }
  }, [UpsertUserData, UpsertUserError])

  if (isLoading) {
    return (
      <AppBar position="fixed" color="primary">
        <Toolbar sx={{ justifyContent: "center" }}>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Box component="img" sx={{height: 24}} alt="logo" src={Logo} />
            <Link
              variant="h6"
              underline="none"
              href="/"
              sx={{ pl: 2, fontSize: 24, color: 'secondary.light' }}
            >
              {en.common.inkBowl}
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    );

  }

  return (
    <React.Fragment>
      <Snackbar
        open={snackbarMessage !== null}
        autoHideDuration={3000}
        message={snackbarMessage}
        onClose={() => setSnackbarMessage(null)}
      />
      <AppBar position="fixed" color="primary">
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{flex: 1, display: "flex", justifyContent: "start", alignItems: "center", minWidth: 250}}>
            <Box component="img" sx={{ height: 24 }} alt="logo" src={Logo} />
            <Link
              variant="h6"
              underline="none"
              href="/"
              sx={{ pl: 2, fontSize: 24, color: "secondary.light" }}
            >
              {en.common.inkBowl}
            </Link>
          </Box>

          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end", minWidth: 650 }}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              sx={{ ...rightLink, color: "secondary.light" }}
            >
              {en.marketing.learnMore}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => window.open("/about")}>
                {en.marketing.about}
              </MenuItem>
              <MenuItem onClick={() => window.open("/guidelines")}>
                {en.marketing.communityGuideline}
              </MenuItem>
            </Menu>

            <Link
              variant="h6"
              underline="none"
              component="button"
              onClick={() => window.open("/dashboard")}
              sx={{ ...rightLink, color: "secondary.light" }}
            >
              {en.common.dashboard}
            </Link>

            <Link
              variant="h6"
              underline="none"
              component="button"
              onClick={() => window.open("/community")}
              sx={{ ...rightLink, color: "secondary.light" }}
            >
              {en.common.community}
            </Link>

            <Link
              variant="h6"
              underline="none"
              component="button"
              onClick={() => window.open("/publications")}
              sx={{ ...rightLink, color: "secondary.light" }}
            >
              {en.common.publications}
            </Link>

            {isAuthenticated && (
              <Link
                variant="h6"
                component="button"
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
                underline="none"
                sx={{ ...rightLink, color: "secondary.light" }}
              >
                {en.common.logout}
              </Link>
            )}

            {!isAuthenticated && (
              <Link
                variant="h6"
                component="button"
                onClick={() => loginWithRedirect()}
                underline="none"
                sx={{ ...rightLink, color: "secondary.light" }}
              >
                {en.common.login}
              </Link>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  );
}

export default AppAppBar;

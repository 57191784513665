import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

import en from '../../../data/en.json';

export default function ProductHero() {
  return (
    <React.Fragment>
      <ProductHeroLayout alignLeft>
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "8%",
            zIndex: 2,
            textAlign: "left",
            color: "black",
            maxWidth: "650px",
            mt: "5vh"
          }}
        >
          {/* "WELCOME TO" */}
          <Typography variant="h4" sx={{ fontWeight: 200, letterSpacing: "1px" }}>
            WELCOME TO
          </Typography>

          {/* "INK" in Blue & "BOWL" in Black */}
          <Typography variant="h2" sx={{ fontWeight: 400 }}>
            <span style={{ color: "#253D97" }}>INK</span>BOWL
          </Typography>

          {/* Subtext */}
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              lineHeight: "1.6",
              marginTop: 2,
              maxWidth: "25vw",
            }}
          >
            An AI-Powered hub for comprehensive student-centered academic, creative & career achievement.
          </Typography>
          {/* Button */}
          <Button
            variant="contained"
            href="/dashboard"
            sx={{
              backgroundColor: "black",
              color: "white",
              textTransform: "none",
              fontSize: "1rem",
              padding: "8px 20px",
              marginTop: 3,
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            {en.marketing.learnMore}
          </Button>
				</Box>
      </ProductHeroLayout>
    </React.Fragment>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

import en from '../../../data/en.json';

export default function AboutHero() {
  return (
    <React.Fragment>
      <ProductHeroLayout alignLeft>
        <Box
          sx={{
            position: "absolute",
            top: "20%",
            left: "8%",
            zIndex: 2,
            textAlign: "left",
            color: "black",
            maxWidth: "650px",
            mt: "5vh"
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 400, letterSpacing: "1px" }}>
              ABOUT
          </Typography>
            <Typography variant="body1" sx={{ fontSize: "1.2rem", fontWeight: 400 }} gutterBottom>
              Realize your vision of success:
          </Typography>
            <Typography variant="h5" component="div">
              Equitable education &<br />
              opportunities for students
          </Typography>
				</Box>
      </ProductHeroLayout>
    </React.Fragment>
  );
}

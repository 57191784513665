import React, { useState, useEffect } from 'react';

import AppAppBar from './views/AppAppBar';
import AppFooter from './views/AppFooter';
import Box from '@mui/material/Box';
import Button from './components/Button';
import Grid from '@mui/material/Grid';
import EssayCraft from '../../assets/essaycraft-v3.png';
import InkBowlPublishing from '../../assets/inkbowl-publishing-v2.png';
import InkLink from '../../assets/inklink-v2.png';
import VerseCanvas from '../../assets/versecanvas-v2.png';
import ProductHero from './views/ProductHero';
import Typography from './components/Typography';
import en from '../../data/en.json';
import withRoot from '../../withRoot';
import { useTransition, animated, AnimatedProps, useSpringRef } from '@react-spring/web';
// Third Party
import parse from 'html-react-parser';

const slides = [
  {
    "title": "EssayCraft.AI",
    "subtitleOne": "Write for any occassion,",
    "subtitleTwo": "flawlessly",
    "descriptionOne": "Adapte your writing to meet diverse teacher expectations and preferences",
    "descriptionTwo": "Revision suggestions tailored to each class, assignment, and purpose",
    "backgroundImage": `linear-gradient(rgba(255, 255, 255, 0),rgba(255, 255, 255, 0)), url(${EssayCraft})`,
    "link": "/about#essaycraft",
    "textColor": "black"
  },
  {
    "title": "InkBowl Publishing",
    "subtitleOne": "Selective literature & art publishing -",
    "subtitleTwo": "digitally and in-print",
    "descriptionOne": "Online year-round and in our biannual multimedia magazine",
    "descriptionTwo": "Acquire credentials and exposure for your work",
    "backgroundImage": `linear-gradient(rgba(255, 255, 255, 0),rgba(255, 255, 255, 0)), url(${InkBowlPublishing})`,
    "link": "/about#inkbowl-publishing",
    "textColor": "white"
  },
  {
    "title": "InkLink",
    "subtitleOne": "A global stage for student",
    "subtitleTwo": "authors & artists",
    "descriptionOne": "Elevate your professional profile on our multimedia social network",
    "descriptionTwo": "Showcase, curate a porfoilo, and connect with prospective clients",
    "backgroundImage": `linear-gradient(rgba(255, 255, 255, 0.2),rgba(255, 255, 255, 0.4)), url(${InkLink})`,
    "link": "/about#inklink",
    "textColor": "black"
  },
  {
    "title": "VerseCanvas.AI",
    "subtitleOne": "Seamless AI-generated art & literature",
    "subtitleTwo": "",
    "descriptionOne": "join the new wave where creativity meets innovation",
    "descriptionTwo": "Enhance and redefine the possibilities of artistic expression",
    "backgroundImage": `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.5)), url(${VerseCanvas})`,
    "link": "/about#versecanvas",
    "textColor": "white"
  }
]
const Main: React.FC = () => {
  const [index, set] = useState(0)
  const onClick = () => set(state => (state + 1) % 4)
  const transRef = useSpringRef()
  /* click to change slide vertically - along y axis */
  const transitions = useTransition(index, {
    ref: transRef,
    keys: index,
    from: { opacity: 0, transform: 'translate3d(0,100%,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0%,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-50%,0)' },
  })

	useEffect(() => {
		transRef.start();

		const interval = setInterval(() => {
			set((prev) => (prev + 1) % slides.length);
		}, 5000); // every 5 seconds

		return () => clearInterval(interval); // cleanup
	}, [index]);

  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />

			<Box
				sx={{
					maxWidth: "50vw", // Adjust to match the width in the image
					textAlign: "center",
					m: "0 auto",
					padding: "20px",
          mt: 4,
          mb: 8
				}}
			>
				<Typography variant="h5" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
					{en.marketing.heroContent.title}
				</Typography>
				<Typography variant="body1" sx={{ mt: 2, fontSize: "1.2rem", lineHeight: "2" }}>
          {en.marketing.heroContent.description}
				</Typography>
			</Box>

      <div style={{ height: '65vh', overflow: 'hidden' }} onClick={onClick}>
        {transitions((style, i) => (
          <animated.div
            style={{
              ...style,
              position: 'absolute',
              width: '100%',
              height: '65%',
              backgroundImage: slides[i].backgroundImage,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              color: 'white',
              flexDirection: 'column',
              textAlign: 'left',
              paddingLeft: "8vw",
              paddingTop: "4vw"
            }}
          >
            <Typography color={slides[i].textColor} align="left" variant="h3" sx={{ fontWeight: "bold", textTransform: "none", mb: 1 }}>
              {slides[i].title}
            </Typography>
            <Typography color={slides[i].textColor} align="left" variant="h5" sx={{ fontSize: "2.2rem", lineHeight: "1.2" }}>
              {slides[i].subtitleOne}
            </Typography>
            <Typography color={slides[i].textColor} align="left" variant="h5" width="50vw" sx={{ fontSize: "2.2rem", lineHeight: "1.2", mb: 7 }}>
              {slides[i].subtitleTwo}
            </Typography>
            <Typography color={slides[i].textColor} align="left" variant="body1" sx={{ fontSize: "1.5rem", fontWeight: 100, lineHeight: "1", mb: 1 }}>
              {slides[i].descriptionOne}
            </Typography>
            <Typography color={slides[i].textColor} align="left" variant="body1" sx={{ fontSize: "1.5rem", fontWeight: 100, lineHeight: "1", mb: 3 }}>
              {slides[i].descriptionTwo}
            </Typography>
						<Box
							sx={{
								position: 'absolute',
								bottom: 20,
								left: '50%',
								transform: 'translateX(-50%)',
								display: 'flex',
								gap: 1,
								zIndex: 10,
							}}
						>
							{slides.map((_, i) => (
								<Box
									key={i}
									onClick={(e) => {
										e.stopPropagation(); // prevent parent onClick (if needed)
										set(i);
									}}
									sx={{
										width: 12,
										height: 12,
										borderRadius: '50%',
										backgroundColor: i === index ? 'white' : 'rgba(255,255,255,0.4)',
										border: i === index ? '1px solid black' : '1px solid transparent',
										cursor: 'pointer',
										transition: 'all 0.3s ease-in-out',
									}}
								/>
							))}
						</Box>
          </animated.div>
        ))}
      </div>

      <Box sx={{ backgroundColor: "black", color: "white", pl: "8vw", pt: "4vw", pb: "4vh" }}>
        <Typography variant="h4" align="left" sx={{fontWeight: "bold", textTransform: "none", color: "white", mb: 1}}>
          {en.marketing.oneCreator.title}
        </Typography>
        <Typography align="left" variant="body1" sx={{lineHeight: "2", fontSize: "1.2rem",  maxWidth: "40vw"}}>
          {parse(en.marketing.oneCreator.description)}
        </Typography>
      </Box>
      <AppFooter />
    </React.Fragment>
  );
};

export default withRoot(Main);


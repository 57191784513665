import React, { useRef, useState, useEffect } from 'react';
import AppAppBar from './views/AppAppBar';
import AboutHero from './views/AboutHero';
import Box from '@mui/material/Box';
import { useTrail, animated } from '@react-spring/web';
import Typography from './components/Typography';
import Grid from '@mui/material/Grid';
import en from '../../data/en.json';
import withRoot from '../../withRoot';
import backgroundImage from '../../assets/offWhite.jpg';


const EssayCraft: React.FC = () => {
  return (
    <React.Fragment>
      {/* SECTION TITLE */}
      <Typography variant="h3" sx={{ textTransform: 'uppercase', fontWeight: 600 }}>
        ESSAYCRAFT.AI
      </Typography>

      {/* MAIN HEADLINE */}
      <Typography variant="h4" sx={{ mt: 1, fontWeight: 'bold' }}>
        Write for any occasion or audience, flawlessly
      </Typography>

      {/* SUBHEADER / INTRO PARAGRAPH */}
      <Typography variant="body1" sx={{ fontSize: "1.2rem", mt: 2, mb: 4 }}>
        Adapt your writing to meet diverse teacher expectations and preferences. <br />
        Revision suggestions tailored to each class, assignment, and purpose.
      </Typography>

      {/* FEATURE GRID */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Grading Bias Detection & Predictive Modeling
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Machine learning-driven analysis of graded assignments detects subtle teacher biases and forecasts criticisms.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Quick-Edit Revisions to Match Instructor Preferences
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Predictive modeling generates fine-tuned suggestions based on individual grading patterns, with fast approvals and course-specific workspaces.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Assignment Customization
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Input assignment type and rubric to receive revision suggestions that align with every requirement.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Presets for Genre & Stylistic Transformation
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Instantly refine your existing draft to match the tone, structure, and conventions of your selected academic or creative genre.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Interactive AI Writing Assistant
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Ask questions, clarify confusion, and track your improvement through conversational feedback.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const InkLink: React.FC = () => {
  return (
    <React.Fragment>
      {/* SECTION TITLE */}
      <Typography variant="h3" sx={{ textTransform: 'uppercase', fontWeight: 600 }}>
        InkLink Network
      </Typography>

      {/* MAIN HEADLINE */}
      <Typography variant="h4" sx={{ mt: 1, fontWeight: 'bold' }}>
        A global stage for student authors & artists
      </Typography>

      {/* SUBHEADER / INTRO PARAGRAPH */}
      <Typography variant="body1" sx={{ fontSize: "1.2rem", mt: 2, mb: 4 }}>
        Elevate your professional profile on our multimedia social network. <br />
        Showcase, curate a formal portfolio, and forge connections.
      </Typography>

      {/* FEATURE GRID */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Your Talent as a Career Gateway
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Literary and artistic skills are demanded across a myriad of industries— from journalism, marketing, fashion, tech, entertainment, education and more.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Professional Profile & Networking
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Promote connectivity with prospective clients, employers, and creative peers by shaping your talent profile, attaching your resume, personal links, and spotlighting sample projects.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Audience Growth & International Visibility
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Increase your exposure and build a global following— a key step in launching your career as a writer or artist.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Build a Formal Digital Portfolio
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Compile your work into a polished, shareable portfolio with a unique link— ideal for applications, outreach, and strengthening your brand.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Become Verified & Highlight Publications
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Display your formal publications and verified status on your profile to secure credibility and prestige.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const focusAreas = [
	{ 
		"tag": "#essaycraft",
    "component": <EssayCraft />
	},
	{
		"tag": "#versecanvas",
    "component": <EssayCraft />
	},
	{
		"tag": "#inklink",
    "component": <InkLink />
	},
	{
		"tag": "#inkbowl-publishing",
    "component": <EssayCraft />
	}
]

const About: React.FC = () => {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]); // Array of refs for each section
  const [visibleSections, setVisibleSections] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleSections((prev) => ({
              ...prev,
              [entry.target.id]: true,
            }));
          }
        });
      },
      { threshold: 0.5 } // Adjust visibility threshold as needed
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <React.Fragment>
      <AppAppBar />
      <AboutHero />
			<Box
				sx={{
					maxWidth: "50vw", // Adjust to match the width in the image
					textAlign: "center",
					m: "0 auto",
					padding: "20px",
          mt: 4,
          mb: 8
				}}
			>
				<Typography variant="h5" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
					{en.marketing.aboutContent.title}
				</Typography>
				<Typography variant="body1" sx={{ mt: 2, fontSize: "1.2rem", lineHeight: "2" }}>
          {en.marketing.aboutContent.description}
				</Typography>
				<Typography variant="body1" sx={{ mt: 2, fontSize: "1.2rem", lineHeight: "2" }}>
          {en.marketing.aboutContent.descriptionTwo}
				</Typography>
			</Box>
			<Box
				sx={{
					maxWidth: "50vw", // Adjust to match the width in the image
					textAlign: "center",
					m: "0 auto",
					padding: "20px",
          mt: 4,
          mb: 8
				}}
			>
				<Typography variant="h5" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
					{en.marketing.aboutContent.exploreOfferings}
				</Typography>
			</Box>

      <Box sx={{ 
        p: 10, pt: 20,
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.45),rgba(255, 255, 255, 0.45)), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
        {focusAreas.map((area, index) => {
          const isVisible = visibleSections[area.tag];
          const trail = useTrail(focusAreas.length, {
            opacity: isVisible ? 1 : 0,
            x: isVisible ? 0 : 20,
            from: { opacity: 0, x: 20, height: 0 },
            config: { mass: 5, tension: 2000, friction: 200 },
          });

          return (
            <animated.div
              key={index}
              id={area.tag} // Anchor point
              ref={(el) => (sectionRefs.current[index] = el)} // Assign ref
              style={{
                ...trail[index],
                padding: '10px',
                height: '80vh',
              }}
            >
              {area.component}
            </animated.div>
          );
        })}
      </Box>
    </React.Fragment>
  );
};

export default withRoot(About);

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { SxProps, Theme } from '@mui/system';

const ProductHeroLayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    height: '65vh',
    minHeight: 350,
    maxHeight: 1200,
  },
}));

const VideoBackground = styled('video')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: -2, // Ensures video stays behind the text
});

interface ProductHeroLayoutProps {
  sxBackground?: SxProps<Theme>;
  alignLeft?: boolean;
}

export default function ProductHeroLayout(
  props: React.HTMLAttributes<HTMLDivElement> & ProductHeroLayoutProps,
) {
  const { alignLeft, children } = props;

  return (
    <ProductHeroLayoutRoot>
      {/* Video Background */}
      <VideoBackground autoPlay loop muted playsInline preload="auto">
        <source src="/videos/cover.mp4" type="video/mp4" />
        {/* <source src="/videos/cover.webm" type="video/webm" /> */}
        Your browser does not support the video tag.
      </VideoBackground>

      <Container
        sx={{
          mt: 3,
          mb: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Align text to top-left */}
        {alignLeft && (
          <Box
            sx={{
              position: 'absolute',
              left: 100,
              top: 50,
              zIndex: 2,
              width: 750,
            }}
          >
            {children}
          </Box>
        )}
        {!alignLeft && children}
      </Container>
    </ProductHeroLayoutRoot>
  );
}
